import * as React from "react"

import CTASection from "../components/partials/cta-section"
import FeturesSection from "../components/features-page"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>
        Rocketadmin features
      </title>
      <meta name="keywords" content="admin panel, database, management, mysql, oracle, postgres, mssql, mongodb, user permissions, records, search, filters, client encryption, log audit" />
      <meta name="description" content="Features of an admin panel for your database." />
    </Helmet>
    <FeturesSection />
    <CTASection />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
